import React from "react";
import "./soon.scss";
import Comingsoon from "../../asset/Hardsell/Hardsell_Desktop.webp";
import Soon from "../../asset/Hardsell/Hardsell_Mobile.webp";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(Hardsell)%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
  window.open(whatsappLink, "_blank");
};

const soon = () => {
  return (
    <div id="Maple" className="container-soon">
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={Comingsoon} alt="maps" />
        <img id="lokasi" className="img-lokasi-mobile" src={Soon} alt="maps" />
      </div>
      <div className="button">
        <div className="container-wa">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default soon;
