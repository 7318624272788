import React from "react";
import "./home.scss";
import Navbar from "../section/navbar/navbar.jsx";
import Header from "../section/header/header.jsx";
import Hardsell from "../section/comingsoon/soon.jsx";
import Produk from "../section/produk/produk.jsx";
import Fasilitas from "../section/fasilitas/fasilitas.jsx";
import Lokasi from "../section/Lokasi/lokasi.jsx";
import Footer from "../section/footer/footer.jsx";
import ScrollToHashElement from "./ScrollToHashElement.js";
import tombol from "../asset/tombol.webp";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(a2)%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Hardsell />
      <Header />
      <Produk />
      <Fasilitas />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
